var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
    _c("div", { staticClass: "px-4 pt-4" }, [
      _c("h6", { staticClass: "mb-0 text-h6 font-weight-bold text-typo" }, [
        _vm._v("Calendar"),
      ]),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          {
            staticClass:
              "p text-sm text-capitalize font-weight-bold mb-0 widget-calendar-day text-body",
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  new Date().toLocaleString("default", { weekday: "long" })
                ) +
                " "
            ),
          ]
        ),
        _c("span", { staticClass: "text-body" }, [_vm._v(",")]),
        _c(
          "div",
          {
            staticClass:
              "p text-sm font-weight-bold mb-1 widget-calendar-year text-body ms-1",
          },
          [_vm._v(" " + _vm._s(new Date().getFullYear()) + " ")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "px-4 py-4" },
      [
        _c("full-calendar", {
          ref: "fullCalendar",
          attrs: { options: _vm.calendarOptions },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
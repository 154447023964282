var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "border-radius-xl overflow-hidden card-shadow",
                            },
                            [
                              _c("div", { staticClass: "px-4 pb-0 pt-4" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm mb-0 text-capitalize font-weight-bold text-body",
                                  },
                                  [_vm._v(" Visitors ")]
                                ),
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-bolder text-h5 text-typo mb-0",
                                  },
                                  [
                                    _vm._v(" 5,927 "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-success text-sm font-weight-bolder",
                                      },
                                      [_vm._v(" +55% ")]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", [
                                _c("div", { staticClass: "chart" }, [
                                  _c("canvas", {
                                    staticClass: "chart-canvas",
                                    attrs: {
                                      id: "chart-line-1",
                                      height: "100",
                                    },
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "border-radius-xl overflow-hidden card-shadow",
                            },
                            [
                              _c("div", { staticClass: "px-4 pb-0 pt-4" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm mb-0 text-capitalize font-weight-bold text-body",
                                  },
                                  [_vm._v(" Income ")]
                                ),
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-bolder text-h5 text-typo mb-0",
                                  },
                                  [
                                    _vm._v(" $130,832 "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-success text-sm font-weight-bolder",
                                      },
                                      [_vm._v(" +90% ")]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", [
                                _c("div", { staticClass: "chart" }, [
                                  _c("canvas", {
                                    staticClass: "chart-canvas",
                                    attrs: {
                                      id: "chart-line-2",
                                      height: "100",
                                    },
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "border-radius-xl overflow-hidden card-shadow border h-100",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-4 py-4 d-flex flex-column justify-center text-center h-100",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-decoration-none",
                                      attrs: { href: "javascript:" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-plus text-secondary text-sm mb-3",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "text-h6 text-secondary font-weight-bold",
                                        },
                                        [_vm._v(" New tab ")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-col", [_c("calendar")], 1)], 1),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "4" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow px-4 py-4 border-radius-xl",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "overflow-hidden position-relative border-radius-lg h-100",
                                  style: `background-image: url(${require("../../assets/img/ivancik.jpg")}); background-size: cover;`,
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "mask bg-gradient-default opacity-8",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-4 py-4 position-relative z-index-1 h-100",
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "text-white text-h6 font-weight-bolder mb-4",
                                        },
                                        [_vm._v(" Hey John! ")]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "text-white mb-4" },
                                        [
                                          _vm._v(
                                            " Wealth creation is an evolutionarily recent positive-sum game. It is all about who take the opportunity first. "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "ms-auto font-weight-bolder py-6 px-5",
                                          attrs: {
                                            outlined: "",
                                            color: "#fff",
                                            small: "",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _vm._v(" Read more "),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "rotate-180",
                                              attrs: { size: "14" },
                                            },
                                            [
                                              _vm._v(
                                                " fas fa-arrow-right text-sm ms-1 "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "12", sm: "6" } },
                        [_c("categories-card")],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "12", sm: "6" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "card-shadow border-radius-xl" },
                            [
                              _c(
                                "div",
                                { staticClass: "px-4 pt-4" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              staticClass:
                                                "w-100 h-100 shadow border-radius-lg",
                                              attrs: {
                                                width: "auto",
                                                height: "auto",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  src: require("@/assets/img/kal-visuals-square.jpg"),
                                                  alt: "Avatar",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "8" } },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-muted text-sm font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " Today is Martina's birthday. Wish her the best of luck! "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "font-weight-bold text-uppercase btn-default bg-gradient-default py-2 px-6 me-2",
                                              attrs: {
                                                elevation: "0",
                                                ripple: false,
                                                height: "43",
                                                color: "#5e72e4",
                                                small: "",
                                              },
                                            },
                                            [_vm._v(" Send Message ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "px-4 pt-4" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { md: "6" } }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "mb-0 text-h6 font-weight-bold text-typo",
                                  },
                                  [_vm._v(" Transactions ")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "text-end", attrs: { md: "6" } },
                                [
                                  _c("v-icon", [
                                    _vm._v("far fa-calendar-alt me-2"),
                                  ]),
                                  _c("small", { staticClass: "text-body" }, [
                                    _vm._v("23 - 30 March 2021"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "px-4 pb-4 pt-2" },
                        _vm._l(_vm.transactions, function (transaction, index) {
                          return _c(
                            "div",
                            { key: transaction.icon },
                            [
                              _c(
                                "v-list-item",
                                {
                                  key: transaction.title,
                                  staticClass: "px-0 py-4",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "me-4",
                                      attrs: {
                                        ripple: false,
                                        icon: "",
                                        outlined: "",
                                        rounded: "",
                                        color: transaction.color,
                                        width: "34px",
                                        height: "34px",
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "8" } }, [
                                        _vm._v(_vm._s(transaction.icon)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "mb-1 text-typo text-sm font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(transaction.title) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-xs text-body",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(transaction.date) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0 text-end" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-sm font-weight-bold text-gradient",
                                          class: transaction.gradient,
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(transaction.value) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("hr", {
                                staticClass: "horizontal",
                                class: {
                                  dark: index !== _vm.transactions.length - 1,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "px-4 pt-4" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { md: "6" } }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "mb-0 text-h6 font-weight-bold text-typo",
                                  },
                                  [_vm._v(" Revenue ")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "text-end", attrs: { md: "6" } },
                                [
                                  _c("v-icon", [
                                    _vm._v("far fa-calendar-alt me-2"),
                                  ]),
                                  _c("small", { staticClass: "text-body" }, [
                                    _vm._v("01 - 07 June 2021"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "px-4 pb-4 pt-2" },
                        _vm._l(_vm.revenues, function (revenue, index) {
                          return _c(
                            "div",
                            { key: revenue.icon },
                            [
                              _c(
                                "v-list-item",
                                {
                                  key: revenue.title,
                                  staticClass: "px-0 py-4",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "me-4",
                                      attrs: {
                                        ripple: false,
                                        icon: "",
                                        outlined: "",
                                        rounded: "",
                                        color: revenue.color,
                                        width: "34px",
                                        height: "34px",
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "8" } }, [
                                        _vm._v(_vm._s(revenue.icon)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "mb-1 text-typo text-sm font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(revenue.title) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-xs text-body",
                                            },
                                            [_vm._v(_vm._s(revenue.date))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0 text-end" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-sm font-weight-bold text-gradient",
                                          class: revenue.gradient,
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(revenue.value) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("hr", {
                                staticClass: "horizontal",
                                class: {
                                  dark: index !== _vm.transactions.length - 1,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }